import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {   id       : 'sel',
        title    : 'Sel',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'sel-shipment-list',
                title: 'Shipment list',
                type : 'item',
                url  : 'apps/sel/sel-book/book-list2',
                icon     : 'dashboard',
                
            },
            
            {
                id       : 'report-shipment',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'sel-shipment-report',
                        title: 'Shipment report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/shipment-report',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-shipment-report2',
                        title: 'Shipment currency report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/shipment-report2',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-revenue-report',
                        title: 'Revenue report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/revenue-report',
                        icon     : 'dashboard',
                    },

                    {
                        id   : 'sel-cost-report',
                        title: 'Cost report',
                        type : 'item',
                        url  : 'apps/sel/sel-report/cost-report',
                        icon     : 'dashboard',
                    },
                    {
                        id   : 'sel-cost-report2',
                        title: 'Cost report 2',
                        type : 'item',
                        url  : 'apps/sel/sel-report/cost-report2',
                        icon     : 'dashboard',
                    },
                ]
            },
        ]
    },
    
    {
        id       : 'Container',
        title    : 'Container',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'dashboard1',
                title: 'Dashboard',
                type : 'item',
                icon     : 'dashboard',
                url  : '/apps/container/dashboard',
            },
            {
                id   : 'cont',
                title: 'Container list',
                type : 'item',
                url  : '/apps/container/list',
                icon     : 'dashboard',
                
            },
            {
                id       : 'cont3',
                title    : 'Container condition',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/container/condition'
            },
            {
                id   : 'cont3',
                title: 'Container import',
                type : 'item',
                url  : '/apps/container/multi-container',
                icon     : 'dashboard',
                 
            },
        ]
    },
    {
        id       : 'movement',
        title    : 'Movement',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'containerList2',
                title    : 'Container list',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/container/list/'
            },
            {
                id       : 'movementList2',
                title    : 'Movement filter',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/list2'
            },
            {
                id       : 'movementImport',
                title    : 'Movement import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/movement/multi-movement'
            },
            {
                id   : 'movementDelete',
                title: 'Movement delete',
                type : 'item',
                icon : 'group',
                url  : '/apps/movement/multi-del',
            },
            {
                id       : 'cont2',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    
                    {
                        id   : 'dashboard2',
                        title: 'Movement report',
                        type : 'item',
                        url  : '/apps/movement/report/movement',
                    },
                ]
            },
        ]
    },
    {
        id       : 'vessel-load',
        title    : 'Vessel loading',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'load-import',
                title    : 'Load import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/list/load',
            },
            {
                id       : 'fulim-import',
                title    : 'Fulim import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/list/fulim',
            },
            /*
            {
                id       : 'load-import',
                title    : 'Load import',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/vessel-load/voyage/import'
            },*/
        ]
    },
    {
        id       : 'Lease',
        title    : 'Lease',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'lease',
                title    : 'Lease',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/lease/list'
            },
            {
                id       : 'lease2',
                title    : 'Report',
                type     : 'collapsable',
                icon     : 'dashboard',
                children : [
                    {
                        id   : 'dashboard2',
                        title: 'Lease Invoice',
                        type : 'item',
                        url  : '/apps/lease/lease-invoice',
                    },
                ]
            },
        ]
    },
    {
        id       : 'setting',
        title    : 'Setting Control',
        type     : 'group',
        icon     : 'settings',
        children : [
            {
                id       : 'vessel',
                title    : 'Vessel',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/vessel/list'
            },
            {
                id       : 'voyage',
                title    : 'Voyage',
                type     : 'item',
                icon     : 'directions_boat',
                url  : '/apps/setting/voyage/list'
            },
        ]
    },
    {
        id       : 'humanResources',
        title    : 'Human Resources',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id   : 'user_time',
                title: 'Time shift',
                type : 'item',
                url  : '/apps/human-resources/user_time',
                icon     : 'dashboard',
                
            },
        ],
    },

];
