import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Location } from '@angular/common';

import { tap, delay } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public isLoggedIn = false;
  public version = '5.08';
  public user;
  public userType;
  public email;
  public name;
  public permission;
  public modul;
  public company;
  public companyMail;
  public accessToken;
  public checkValue = '';
  public data;
  public branch;
  public signIn = false;
  public back = false;
  public tags = [
        {'id': 1, 'tag': '20DC', 'color' : '#388E3C'},
        {'id': 2, 'tag': '40HC', 'color' : '#F44336'},
        {'id': 3, 'tag': 'Import', 'color' :'#FF9800'},
        {'id': 4, 'tag': 'Export', 'color' : '#0091EA'},
        {'id': 5, 'tag': 'Asia', 'color' : '#9C27B0'},
        {'id': 6, 'tag': 'Europe', 'color' : '#9C27B0'},
        {'id': 7, 'tag': 'America', 'color' : '#9C27B0'},
        {'id': 8, 'tag': 'Australia', 'color' : '#9C27B0'}
    ];
  
  //public apiUrl = 'http://localhost:8080';
  //public apiUrl = 'https://api.ishipto.vn';
  public apiUrl = 'https://ishipto-node10.appspot.com';

  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  public web;
  onModulChanged: BehaviorSubject<any>;

  constructor(
    private location: Location
  ){
    this.onModulChanged = new BehaviorSubject({});
  }
  
  public login(data): void{
    this.isLoggedIn = true;
    this.accessToken = data.accessToken;
    this.user = data.id;
    this.userType = data.type;
    this.name = data.name;
    this.email = data.email;
    this.permission = data.permission;
    this.company = data.company;
    this.companyMail = data.company_mail;
    this.web = data.web;
  }
  public logout(): void {
    this.isLoggedIn = false;
    this.accessToken = null;
    this.user = null;
    this.userType = null;
    this.name = null;
    this.email = null;
    this.permission = null;
    this.company = null;
    this.web = null;
  }
  public check(user): void{
    this.checkValue = btoa(user.email + ':' + user.password);
  }

  getPermission(modul, type){
    let permission = this.permission.find(item => item.modul == modul);
    if(typeof permission == 'undefined'){
      return 0;
    }
    return permission[type];
  }
  getAction(modul){
      let permission = this.permission.find(item => item.modul == modul);
      let action
      if(permission){
        action = {
          id: permission.id,
          create: permission.create_data == 1 ? true : false,
          read: permission.read_data == 1 ? true : false,
          edit: permission.update_data == 1 ? true : false,
          delete: permission.delete_data == 1 ? true : false,
          data: permission.data == 'public' ? true : false,
          web: this.web
        }
      } else {
        action = {
          create: false,
          read: false,
          edit: false,
          delete: false,
          data: false,
          web: this.web
        }
      }
      return action;
  }
  doLogout(){
    return new Promise((resolve, reject) => {
      if(this.isLoggedIn){
        this.logout();
        resolve();
      }
      else{
        reject();
      }
    });
  }
  goBack(){
    this.location.back();
    this.back = true;
  }
  checkSelShipmentStatus(shipment){
      if(shipment.status == 2) {
          return true
      } else {
          return false
      }
  }
}